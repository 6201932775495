import './../css/home.css';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

interface Props {
    content: any
}

interface State {
    backgroundStyle: any
}

class Home extends React.Component<Props, State> {
 
    state: State = {
        backgroundStyle: null
    }

    componentDidMount() {
        this.setState({
            backgroundStyle: { 
                backgroundImage: 'url(' + process.env.REACT_APP_STRAPI_URL + this.props.content.backgroundImage.data.attributes.url + ')',
                backgroundPosition: 'center center',
                backgroundSize: 'cover'
            }
        });
    }

    render() {
        return (
            <div id="home" className="page" style={this.state.backgroundStyle}> 
                {this.props.content &&
                    <Helmet>
                        <title>{this.props.content.metaTitle}</title>
                        <meta property="og:title" content={this.props.content.metaTitle} />
                        <meta name="description" content={this.props.content.metaDescription} />
                        <meta property="og:description" content={this.props.content.metaDescription} />
                        <meta property="og:image" content={process.env.REACT_APP_STRAPI_URL + this.props.content.metaImage.data.attributes.url} />
                    </Helmet>
                }
                <div className="content">
                    {this.props.content && 
                        <div>
                            <div id="home-title"><h1>{this.props.content.title.replaceAll(" ", "\n")}</h1></div>
                            <div id="home-description"><BlocksRenderer content={this.props.content.text} /></div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default Home;