import React from 'react'
import './css/style.css';
import Menu from './components/menu';
import Home from './components/home';
import { HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import Page from './components/page';

interface Props {
    
}

interface State {
    currentPage: string,
    scrolling: boolean,
    locale: string,
    scrollTimeout?: NodeJS.Timeout
    pages: any
    home: any
}

class App extends React.Component<Props, State> {

    state: State = {
        currentPage: "home",
        scrolling: false,
        locale: 'en',
        scrollTimeout: undefined,
        pages: null,
        home: null
    }

    componentDidMount() {
        // Get home (and pages after that)
        this.getHome(this.state.locale);

        // Set scroll event listener
        window.addEventListener('scroll', this.updatePageFromScroll);
    }

    getHome = (locale: string) => {
        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/home?populate=deep&locale=' + locale)
            .then((response: any) => { 
                this.setState({
                    home: response.data.data.attributes
                }, () => {
                    this.getPages(locale);
                });
            })
            .catch((error: any) => 
            {
                if(locale !== 'en' && error.response && error.response.status === 404)
                {
                    console.log('Error: Could not find localized content > loading English');
                    this.getHome('en');
                }
                else
                {
                    console.log(error.message);
                }
            });
    }

    getPages = (locale: string) => {
        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/pages?populate=deep&locale=' + locale)
            .then((response: any) => { 
                this.setState({
                    pages: response.data.data
                }, () => {
                    this.scrollToPageFromURL();
                });
            })
            .catch((error: any) => 
            {
                if(locale !== 'en' && error.response && error.response.status === 404)
                {
                    console.log('Error: Could not find localized content > loading English');
                    this.getPages('en');
                }
                else
                {
                    console.log(error.message);
                }
            });
    }

    updatePageFromScroll = () => {

        if(!this.state || this.state.scrolling) return;

        //console.log('scrolling: ' + window.scrollY);
        const elements = document.getElementsByClassName("page");
        if(elements)
        {
            Array.from(elements).forEach(element => {
                const elementY = element.getBoundingClientRect().top + window.scrollY - 66;
                if(window.scrollY > elementY - 200 && window.scrollY < elementY + 200)
                {
                    var page = element.id;
                    if(page === "home") page = "";
                    this.setState({currentPage: page});

                    var path = window.location.pathname.replace("/","");
                    if(path !== page)
                    {
                        // locale
                        var locale = "/" + this.state.locale;
                        if(locale === "/en") locale = "";

                        //console.log("Setting push: " + locale + "/" + page);
                        window.history.pushState({}, "", locale + "/" + page);
                    }
                }
            });
        }
        
    }

    setCurrentPage = (page: string) => {

        // locale
        var locale = "/" + this.state.locale;
        if(locale === "/en") locale = "";

        // Presskit
        if(page === "presskit") page = "contact";

        // Set url
        var history = page;
        if (!history) history = "";
        window.history.pushState({}, "", locale + "/" + history); // Set url in browser

        // Clear the previous timeout if it is still running
        clearTimeout(this.state.scrollTimeout); 
        
        // Set State (pass the timeout to disable 'is scrolling' to scrollTimeout so we can clear it)
        this.setState({currentPage: page, scrolling: true, scrollTimeout: setTimeout(() => this.setState({scrolling: false}), 800)}, () =>{
            this.scrollToPage(page, 'smooth', true);
        });
    }

    scrollToPage = (page: string, behavior: string, offset: boolean) => {
        // Scroll to the page
        var y = 0;
        const element = document.getElementById(page);
        if (element) y = element.getBoundingClientRect().top + window.scrollY;
        if (offset) y -= 66;
        window.scroll({
            top: y,
            behavior: behavior as ScrollBehavior
        });
    }

    scrollToPageFromURL = () =>
    {
        //console.log('Pages loaded: Scrolling to page from url');

        var params = window.location.pathname.slice(1).split("/"); // Remove leading slash and split on subsequent slashes
        var locale = "en";
        var newPage = "";

        if(params[0] === "en" || params[0] === "fr" || params[0] === "ar" )
        {
            locale = params[0];
            newPage = params[1];
        }
        else
        {
            newPage = params[0];
        }

        var pageFromUrl = newPage;
        if(pageFromUrl === "presskit") pageFromUrl = "contact";

        this.setState({
            locale: locale,
            currentPage: newPage, 
            scrolling: false
        }, () => {
            // Update html class
            document.getElementsByTagName('html')[0].setAttribute('class', locale);
            this.scrollToPage(pageFromUrl, 'auto', true);
        });
    }

    setLocale = (locale: string) =>
    {
        this.setState({
            locale: locale
        }, () => {
            // update page
            this.setCurrentPage(this.state.currentPage);
            // Update html class
            document.getElementsByTagName('html')[0].setAttribute('class', locale);
            // Get localized content
            this.getPages(this.state.locale);
        });
    }

    render()
    {
        return (
            <div className={`app ${this.state.locale}`}>
                {this.state.pages && this.state.home && 
                    <HelmetProvider>
                        {/* <Menu pages={this.state.pages} home={this.state.home} currentPage={this.state.currentPage} setCurrentPage={this.setCurrentPage} setLocale={this.setLocale} locale={this.state.locale} /> */}
                        <Home content={this.state.home} />
                        {/*this.state.pages.map((page:any) => 
                            <Page key={page.attributes.slug} content={page.attributes} />
                        )*/}
                    </HelmetProvider>
                }
            </div>
        )
    }
}

export default App;
